<template>
  <div id="faq-list">
    <v-card>
      <v-card-title>問い合わせ一覧</v-card-title>
      <v-row class="px-2 ma-0">
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="faq_sei_kana"
            placeholder="セイ"
            outlined
            dense
            hide-details
            @change="changeSeiKana"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="faq_mei_kana"
            placeholder="メイ"
            outlined
            dense
            hide-details
            @change="changeMeiKana"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="faq_tel"
            placeholder="電話番号"
            outlined
            dense
            hide-details
            @change="changeTelephoneNum"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="status"
            :items="statuss"
            placeholder="状態"
            outlined
            clearable
            dense
            hide-details
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="dateStartMenu"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :nudge-right="40"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                placeholder="問い合わせ日From"
                dense
                hide-details
                readonly
                outlined
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                placeholder="問い合わせ日To"
                dense
                outlined
                hide-details
                readonly
                v-bind="attrs"
                :prepend-icon="icons.mdiCalendar"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field
          v-model="search"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :to="{ name: 'faq-create', params: { query: this.$route.query } }"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>新規</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 me-3"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="faqList"
        sort-by
        class="elevation-1"
        :search="search"
      >
        <template v-slot:[`item.name`]="{ item }">
          <div>
            <span>{{ item.faq_sei }} {{ item.faq_mei }}</span>
          </div>
        </template>
        <template v-slot:[`item.faq_code`]="{ item }">
          <div>
            <span>{{ statuss[item.faq_code] ? statuss[item.faq_code].text : '' }}</span>
          </div>
        </template>
        <template v-slot:[`item.faq_content`]="{ item }">
          <div class="content" :title="item.faq_content">
            {{ item.faq_content }}
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-end">
            <div class="py-2 pl-1">
              <v-icon
                small
                @click="editFaq(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
            </div>
            <delete-form
              :id="item.id"
              class="py-2 pl-1"
              @deleted="loadData()"
              @cancel="loadData()"
            ></delete-form>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiCalendar,
  mdiPencil,
  mdiDelete,
  mdiPlus,
  mdiMagnify,
  mdiExportVariant,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

import DeleteForm from '@/views/bimi/faq/FaqDelete.vue'

export default {
  components: {
    DeleteForm,
  },
  data: () => ({
    startDate: '',
    endDate: '',
    dateEndMenu: '',
    dateStartMenu: '',
    search: '',
    faq_tel: undefined,
    faq_sei_kana: undefined,
    faq_mei_kana: undefined,
    status: '',
    statuss: [
      {
        text: '未処理', value: 0,
      },
      {
        text: '受理', value: 1,
      },
      {
        text: '回答済', value: 2,
      },
      {
        text: '要確認', value: 3,
      },
      {
        text: '要追跡', value: 4,
      },
    ],
    icons: {
      mdiCalendar,
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiMagnify,
      mdiExportVariant,
    },
    headers: [
      {
        text: 'ID',
        value: 'id',
        align: 'left',
        sortable: true,
        width: '7%',
        fixed: true,
      },
      {
        text: '姓',
        value: 'faq_sei',
        align: ' d-none',
      },
      {
        text: '名',
        value: 'faq_mei',
        align: ' d-none',
      },
      {
        text: 'セイ',
        value: 'faq_sei_kana',
        align: ' d-none',
      },
      {
        text: 'メイ',
        value: 'faq_mei_kana',
        align: ' d-none',
      },
      {
        text: '処理メモ',
        value: 'faq_memo',
        align: ' d-none',
      },
      {
        text: '問い合わせ者',
        value: 'name',
        align: 'left',
        sortable: true,
        width: '12%',
        fixed: true,
      },
      {
        text: 'メールアドレス',
        value: 'faq_email',
        width: '14%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '電話番号',
        value: 'faq_tel',
        width: '14%',
        align: 'left',
        sortable: true,
        fixed: false,
      },
      {
        text: '問い合わせ日',
        value: 'faq_date',
        width: '13%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '問い合わせ内容',
        value: 'faq_content',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '状態',
        value: 'faq_code',
        width: '15%',
        align: 'center',
        sortable: true,
        fixed: true,
      },
      {
        text: ' ',
        value: 'actions',
        width: '5%',
        align: 'center',
        sortable: false,
        fixed: true,
      },
    ],
  }),
  computed: {
    ...mapState('faqStore', ['faqList']),
  },
  watch: {
    status(newValue) {
      if (typeof newValue === 'number') {
        this.changeRouteQuery('faq_code', newValue.toString())
      } else {
        this.changeRouteQuery('faq_code', newValue)
      }
      this.loadData()
    },
    startDate(newValue) {
      this.changeRouteQuery('startDate', newValue)
      this.loadData()
    },
    endDate(newValue) {
      this.changeRouteQuery('endDate', newValue)
      this.loadData()
    },
  },
  created() {
    this.loadQuery()
    this.changeRouteQuery()
    this.loadData()
  },
  methods: {
    ...mapActions('faqStore', ['loadFaqList']),
    ...mapMutations('app', ['setOverlayStatus']),
    changeSeiKana() {
      this.changeRouteQuery('faq_sei_kana', this.faq_sei_kana)
      this.loadData()
    },
    changeMeiKana() {
      this.changeRouteQuery('faq_mei_kana', this.faq_mei_kana)
      this.loadData()
    },
    changeTelephoneNum() {
      this.changeRouteQuery('faq_tel', this.faq_tel)
      this.loadData()
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadFaqList(this.$route.query).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    editFaq(item) {
      this.$router.push({
        name: 'faq-edit',
        params: {
          id: item.id, query: this.$route.query,
        },
      })
    },

    changeRouteQuery(key, val) {
      const query = JSON.parse(JSON.stringify(this.$route.query))
      if (val) {
        query[key] = val
      } else {
        delete query[key]
      }

      this.$router.push({
        path: this.$route.path, query,
      })
    },

    loadQuery() {
      this.faq_sei_kana = this.$route.query.faq_sei_kana ?? ''
      this.faq_mei_kana = this.$route.query.faq_mei_kana ?? ''
      this.faq_tel = this.$route.query.faq_tel ?? ''
      // eslint-disable-next-line radix
      this.status = this.$route.query.faq_code ? parseInt(this.$route.query.faq_code) : ''
      this.startDate = this.$route.query.startDate ?? ''
      this.endDate = this.$route.query.endDate ?? ''
    },
  },
}
</script>
<style>
.content {
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  width: 10rem;
}
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
