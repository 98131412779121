<template>
  <div justify="center">
    <v-icon
      small
      @click.stop="openForm"
    >
      {{ icons.mdiDelete }}
    </v-icon>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span
            class="text-h5"
            style="color:red"
          >問い合わせ―削除</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="apierror.status=='error'">
              <div
                v-for="msg of apierror.message"
                :key="msg"
              >
                <v-row class="ml-6 mb-3 ma-3">
                  <span style="color: red">* {{ msg }} </span>
                </v-row>
              </div>
            </template>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="faqName"
                  label="問い合わせ者"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.faq_email"
                  label="メールアドレス"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="item.faq_date"
                  label="問い合わせ日"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="faqStatus"
                  label="問い合わせ状態"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="item.faq_content"
                  label="問い合わせ内容"
                  readonly
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="deleteStatus"
            @click="deleteItem(item)"
          >
            YES
          </v-btn>
          <v-btn
            color="error"
            @click="closeForm()"
          >
            NO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiDelete,
} from '@mdi/js'
import {
  mapState, mapMutations, mapActions,
} from 'vuex'

export default {
  props: ['id'],
  data: () => ({
    dialog: false,
    apierror: {
      code: '',
      status: '',
      message: [],
    },
    icons: {
      mdiDelete,
    },
    deleteStatus: false,
    item: {
    },
    statuss: [
      {
        text: '未処理', value: 0,
      },
      {
        text: '受理', value: 1,
      },
      {
        text: '回答済', value: 2,
      },
      {
        text: '要確認', value: 3,
      },
      {
        text: '要追跡', value: 4,
      },
    ],
  }),
  computed: {
    ...mapState('faqStore', ['selectedFaq']),
    faqName() {
      const sei = this.item.faq_sei ?? ''
      const mei = this.item.faq_mei ?? ''

      return `${sei} ${mei}`
    },
    faqStatus() {
      let Status = ''
      if (this.item.faq_code || this.item.faq_code === 0) {
        Status = this.statuss[this.item.faq_code].text
      }

      return Status
    },
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('faqStore', ['loadFaq', 'deleteFaq']),

    openForm() {
      this.setOverlayStatus(true)
      this.loadFaq(this.id).then(() => {
        this.item = {
          ...this.selectedFaq,
        }
        this.setOverlayStatus(false)
        console.log('this.item:', this.items, this.selectedFaq)
      })
      this.dialog = true
    },
    closeForm() {
      this.$emit('cancel')
      this.apierror.messages = []
      this.dialog = false
    },

    deleteItem(item) {
      this.apierror.messages = []
      this.deleteStatus = true
      this.deleteFaq(item.id).then(() => {
        this.dialog = false
        this.deleteStatus = false
        this.$emit('deleted')
      }).catch(error => {
        this.deleteStatus = false
        this.apierror.status = 'error'
        this.apierror.messages = error.response.data.messages

        // this.apierror.messages.push('削除できません。')
        console.log('apierror', this.apierror)
      })
    },

    getContractID(id) {
      return `CF-${id.toString()}`
    },
  },

}
</script>
